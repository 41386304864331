import Axios from 'axios';
import $ from 'jquery';
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Header from '../../Headers/Header';
import '../Inward/ViewInward.css';

import exportFromJSON from 'export-from-json';
import { search } from './utils';
// import { Cookie } from 'express-session';
// import { session } from 'passport';
// import ReactExport from 'react-export-excel'

// const ExcelFile = ReactExport.ExcelFile
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

var convertData, convertData1;
var arrSave = [];

class ViewInward extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      inwards: [],
      loading: '',
      value: '',
      inwardData: [],
      inwardDetailData1: [],
      token: localStorage.getItem('token').toString(),
      pager: {},
      pageOfItems: [],
      nextpage: '',
      page: {},
      name: '',
      status: '',
      count: 1,
      arrVisib: [],
      getVisib: [],
      getFormats: [],
      viewFormats: [],
      classNames1: [],
      arr3: [],
      difference: [],
      diff2: [],
      convertData1: '',
      formatedData: [],
      diff: [],
      getAllRec: [],
      dropValue: '',
      saveVisib: [],
      arr2: [],
      rollbackStatus: 0,
      InwardType: 'Natural',
      partyList: []
    };
    // this.hideShowColumn = this.hideShowColumn.bind(this);

    // this.handleOnInputChange = this.handleOnInputChange.bind(this)
    // this.resetText = this.resetText.bind(this)
    this.hideShowColumn = this.hideShowColumn.bind(this);
    this.saveVisibility = this.saveVisibility.bind(this);
    this.handleOnInputChange = this.handleOnInputChange.bind(this);
    this.resetText = this.resetText.bind(this);
    this.dropDownValueChange = this.dropDownValueChange.bind(this);
    this.exportAll = this.exportAll.bind(this);
    this.updateStock = this.updateStock.bind(this);
    this.rollbackLast = this.rollbackLast.bind(this);
    this.dropDownInwardType = this.dropDownInwardType.bind(this);
  }
  hideColumn() {
    this.state.arr3 = this.state.convertData1.split(',');

    this.state.difference = $(this.state.classNames1)
      .filter(this.state.arr3)
      .get();
    this.state.diff2 = $(this.state.classNames1).not(this.state.arr3).get();

    var arr4 = [];
    var viewHidden = [];
    this.state.difference.forEach((ele) => {
      $("table#example1 th[class='" + ele + "']").show();
      $("table#example1 td[class='" + ele + "']").show();
      $("table#example2 th[class='" + ele + "']").show();
      $("table#example2 td[class='" + ele + "']").show();
    });
    this.state.diff2.forEach((ele) => {
      $("table#example1 th[class='" + ele + "']").hide();
      $("table#example1 td[class='" + ele + "']").hide();
      $("table#example2 th[class='" + ele + "']").hide();
      $("table#example2 td[class='" + ele + "']").hide();
    });
  }

  rollbackLast(e) {
    Axios.get('/deleteLastImportedFile').then((res) => {
      this.setState({
        rollbackStatus: res.data.documentDeleted,
      });
      swal('' + res.data.documentDeleted + ' RECORDS DELETED!', {
        buttons: false,
        icon: 'success',
        timer: 1500,
      });
      // this.componentDidMount()
    });
  }

  dropDownInwardType(e) {
    this.state.InwardType = e.target.value;

    this.componentDidMount();
  }
  dropDownValueChange(e) {
    //this.loadPage(this.state.name)
    this.setState({
      dropValue: e.target.value,
      saveVisib: [],
    });
    Axios.get(`/users/formatWiseValue/${e.target.value}`).then((res) => {
      this.setState({
        formatedData: res.data,
      });
    });

    Axios.get(`/users/viewFormatData/${e.target.value}`).then((res) => {
      this.setState({
        viewFormats: res.data,
      });
      this.state.viewFormats.forEach((dt) => {
        this.state.convertData1 = dt.FormatFields;
      });
      var classArray = [];

      $('td').each(function () {
        var i = $(this).attr('class');
        classArray.push(i);
      });
      this.setState({
        classNames1: classArray,
      });
      this.hideColumn();
    });
  }

  hideShowColumn(e, columnName) {
    if (!e.target.checked) {
      this.state.arrVisib.push(e.target.value);
    }
    this.state.arrVisib = [...new Set(this.state.arrVisib)];

    if (e.target.checked) {
      $("table#example1 th[class='" + columnName + "']").show();
      $("table#example1 td[class='" + columnName + "']").show();
    } else {
      $("table#example1 th[class='" + columnName + "']").hide();
      $("table#example1 td[class='" + columnName + "']").hide();
    }
  }
  ExportExcel(e, id) {
    e.preventDefault();
    Axios.get(`/users/CsvExport/${id}`).then((res) => {
      var now = new Date();
      // var blob = new Blob([JSON.stringify(res)], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      // saveAs(blob, "inward.xlsx");
      const data = res.data;
      const fileName =
        now.getDay() +
        '_' +
        now.getMonth() +
        '_' +
        now.getFullYear() +
        '_Inward';
      const exportType = 'csv';

      exportFromJSON({ data, fileName, exportType });
    });
  }
  DeleteInward(e, id) {
    e.preventDefault();
    swal({
      title: 'Are you sure?',
      text: 'You want to delete this record?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.get(`/users/deleteOneInward/${id}`).then((res) => {
          swal('Record Deleted Successfully!', {
            button: false,
            timer: 1500,
            icon: 'success',
          });
          this.setState({
            value: '',
          });
          this.componentDidMount();
        });
      } else {
        swal('Canceled');
      }
    });
  }
  handleOnInputChange = async (event) => {
    this.search(event.target.value);

    this.setState({
      value: event.target.value,
    });
  };

  search = async (val) => {
    if (this.state.getVisib != '') {
      this.state.getVisib.forEach((arr) => {
        convertData = arr.ColumnNames;
      });
      var arr2 = [];

      arr2 = convertData.split(',');

      arr2.forEach((data1) => {
        $("th[class='" + data1 + "']").hide();
        $("td[class='" + data1 + "']").hide();
      });

      arr2.forEach((data1) => {
        $(":checkbox[value='" + data1 + "']").prop('checked', false);
        $(":checkbox[value='" + data1 + "']").prop('checked', false);
      });
    }

    this.setState({ loading: true, arr3: this.state.convertData1.split(',') });

    // const res = await Axios(

    if (this.state.dropValue != '') {
      const res = await search(
        `/users/getSerchedInwardFormat/${val}/${this.state.dropValue}`
      );

      const inwards = res;
      // this.state.arr3 = convertData1.split(",")
      this.hideColumn();

      this.setState({ inwards, loading: false });
    } else {
      if (this.state.saveVisib != '') {
        const res = await search(
          `/users/getSerchedInwardHidden/${val}/${this.state.saveVisib}`
        );

        const inwards = res;
        console.log("Inwards is :  ",{ inwards });
        // this.state.arr3 = convertData1.split(",")
        this.hideColumn();

        this.setState({ inwards, loading: false });
      } else {
        const res = await search(`/users/getSerchedInward/${val}`);

        const inwards = res;
        // this.state.arr3 = convertData1.split(",")
        this.hideColumn();

        this.setState({ inwards, loading: false });
      }
    }
  };
  loadPage(name, click) {
    //this.csvData()

    const params = new URLSearchParams(window.location.search);
    this.state.page = parseInt(params.get('page')) || 1;
    this.state.name = name;

    if (this.state.page !== this.state.pager.currentPage) {
      Axios.get(
        `/users/InwardDetailData?page=${this.state.page}&name=${this.state.name}&InwardType=${this.state.InwardType}`
      ).then((res) => {
        this.setState({
          pager: res.data.pager,
          pageOfItems: res.data.pageOfItems,
          nextpage: window.location.search,
          status: res.data.status,
        });

        Axios.get('/users/getVisib').then((res) => {
          this.setState({
            getVisib: res.data.data,
          });

          if (this.state.getVisib != '') {
            this.state.getVisib?.forEach((arr) => {
              convertData = arr.ColumnNames;
            });
            // var arr2 = []

            this.state.arr2 = convertData.split(',');

            this.state.arr2.forEach((data1) => {
              $("th[class='" + data1 + "']").hide();
              $("td[class='" + data1 + "']").hide();
            });

            this.state.arr2.forEach((data1) => {
              $(":checkbox[value='" + data1 + "']").prop('checked', false);
              $(":checkbox[value='" + data1 + "']").prop('checked', false);
            });
          }
        });
      });
    }
  }

  csvData() {
    var arrSave1 = [];

    $('input:checkbox:not(:checked)').each(function () {
      arrSave1.push($(this).val());
    });
    this.setState({
      saveVisib: arrSave1,
    });

    if (this.state.saveVisib == '') {
      Axios.get('/users/getAll').then((res) => {
        this.setState({
          formatedData: [],
          getAllRec: res.data,
        });
      });
    } else {
      Axios.get(`/users/hiddenCol/${this.state.saveVisib}`).then((res1) => {
        this.setState({
          getAllRec: res1.data,
        });
      });
    }
  }
  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.loadPage(this.state.name);
    });
  }

  saveVisibility() {
    var arrSave = [];
    $('input:checkbox:not(:checked)').each(function () {
      arrSave.push($(this).val());
    });
    this.setState({
      saveVisib: arrSave,
    });

    if (arrSave == '') {
      Axios.get('/users/delVisib').then((res) => {
        this.loadPage(this.state.name);
        this.bindData();
        swal('Saved', {
          button: false,
          timer: 3000,
          icon: 'success',
        });
      });
    } else {
      Axios.post(`/users/saveVisib/${arrSave}`).then((res) => {
        // swal("Saved", "", "success")
        this.loadPage(this.state.name);
        this.bindData();
        swal('Saved', {
          button: false,
          timer: 3000,
          icon: 'success',
        });
      });
    }
  }

  bindData() {
    Axios.get('/users/getVisib').then((res) => {
      this.setState({
        getVisib: res.data.data,
      });

      if (this.state.getVisib != '') {
        this.state.getVisib.forEach((arr) => {
          convertData = arr.ColumnNames;
        });
        // var arr2 = []

        this.state.arr2 = convertData.split(',');

        this.state.arr2.forEach((data1) => {
          $("th[class='" + data1 + "']").hide();
          $("td[class='" + data1 + "']").hide();
        });

        this.state.arr2.forEach((data1) => {
          $(":checkbox[value='" + data1 + "']").prop('checked', false);
          $(":checkbox[value='" + data1 + "']").prop('checked', false);
        });
      }
      if (this.state.getVisib == '') {
        Axios.get(`/users/getAll/${this.state.InwardType}`).then((res) => {
          this.setState({
            formatedData: [],
            getAllRec: res.data,
          });
        });
      } else {
        Axios.get(
          `/users/hiddenCol/${this.state.arr2}/${this.state.InwardType}`
        ).then((res1) => {
          this.setState({
            getAllRec: res1.data,
          });
        });
      }

      Axios.get('/users/getFormats').then((res) => {
        this.setState({
          getFormats: res.data,
        });
      });
    });
    Axios.get('/users/ViewPartyDetails').then((res) => {
      // prints all supplier
      // console.log("/users/ViewPartyDetails response is : ", res.data);
      const markUps = {};
      const newMarkUps = {};
      res.data.forEach((party) => {
        markUps[party.partyName] = party.FancyMarkup;

        if (party.IsActive) {
          newMarkUps[party?.partyName] = {
            FancyMarkup: party?.FancyMarkup,
            Markup: party?.Markup,
            WhiteMarkup: party?.WhiteMarkup,
            discount: party?.discount,
            // VendorDiscount: party?.VendorDiscount,
          };
        }
      });

      // the markUps object stores all parties along with their FancyMarkup value
      // console.log("Markups are :  ", markUps);
      this.setState({
        newMarkUpState: newMarkUps,
        partyMarkup: markUps,
        partyList: res.data,
      });
    });
  }

  componentDidMount() {
    this.setState({
      classNames1: [],
    });

    this.loadPage(this.state.name);
    this.bindData();

    Axios.get('/CountDeletedData').then((res) => {
      this.setState({
        rollbackStatus: res.data.count,
      });
    });
  }
  exportAll() {
    //  this.csvData()
    var data = [];

    if (this.state.formatedData == '') {
      if (this.state.getAllRec == '') {
        alert('Data is not loaded yet');
        return;
      } else {
        data = this.state.getAllRec;
      }
    } else {
      data = this.state.formatedData;
    }

    var now = new Date().toLocaleDateString();
    const fileName = now + '_Inward';
    const exportType = 'csv';

    exportFromJSON({ data: data, fileName, exportType });
    swal('Data Exported', {
      button: false,
      timer: 1500,
      icon: 'success',
    });
  }
  CsvAfterSearch(e, val) {
    var now = new Date().toLocaleString();

    this.state.diff = $(this.state.inwards).not(this.state.formatedData).get();

    //   var diff2 = Object.values(this.state.diff)
    var data = [];
    data = this.state.inwards;
    // const data = this.state.formatedData

    const fileName = now + '_Inward';
    const exportType = 'csv';

    exportFromJSON({ data, fileName, exportType });
    swal('Data Exported', {
      button: false,
      timer: 3000,
      icon: 'success',
    });
  }
  updateStock(e) {
    Axios.get('/updateStockRapnet').then((res) => {
      if (res.data.msg) {
        swal('UPDATED!', '', 'success');
      }
    });
  }
  resetText(e) {
    this.setState({
      //status: '',
      // inwards: [],
      // loading: '',
      value: '',
      // inwardData: [],
      // inwardDetailData1: [],
      // token: localStorage.getItem('token').toString(),
      // name: '',
      // status: '',
      // count: 1,
      arrVisib: [],
      getVisib: [],
      viewFormats: [],
      classNames1: [],

      difference: [],
      diff2: [],
      convertData1: '',
      formatedData: [],
      diff: [],
      dropValue: '',
      getCsvFormat: [],
    });

    $('#drop').prop('selectedIndex', 0);
    $('table td').show();
    $('table th').show();
    Axios.get('/users/getVisib').then((res) => {
      this.setState({
        getVisib: res.data.data,
      });
      if (this.state.getVisib != '') {
        this.state.getVisib.forEach((arr) => {
          convertData = arr.ColumnNames;
        });
        var arr2 = [];

        arr2 = convertData.split(',');

        arr2.forEach((data1) => {
          $("th[class='" + data1 + "']").hide();
          $("td[class='" + data1 + "']").hide();
        });

        arr2.forEach((data1) => {
          $(":checkbox[value='" + data1 + "']").prop('checked', false);
          $(":checkbox[value='" + data1 + "']").prop('checked', false);
        });
      }
    });
  }
  
  render() {
    const { viewinward, pageOfItems, pager } = this.state;
    return (
      <div>
        <Header />
        <div className="wrapper">
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>Manage Inward</h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item">
                        <a href="#" style={{ color: "#17a2b8" }}>
                          Home
                        </a>
                      </li>
                      <li className="breadcrumb-item active">View Inward</li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <Link to="/addInward" className="btn btn-info">
                      <i className="fa fa-plus"></i>&nbsp;New Inward
                    </Link>

                    <div className="row">
                      <div className="col-md-6 pt-2">
                        <textarea
                          type="text"
                          className="form-control "
                          name="value"
                          placeholder="Stock || VendorStock || Certificate"
                          value={this.state.value}
                          onChange={this.handleOnInputChange}
                        />
                      </div>
                      <div className="col-md-6 pt-2">
                        <input
                          type="reset"
                          onClick={this.resetText}
                          className="btn btn-secondary"
                          value="Reset"
                        />
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">View Inward Details</h3>
                      </div>
                      <div style={{ margin: "5px 20px" }}>
                        <button
                          class="btn btn-secondary dropdown-toggle dropdown-set"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Column visibility
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ marginLeft: "3px" }}
                          onClick={this.saveVisibility}
                        >
                          Save Visibility
                        </button>

                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ marginLeft: "3px" }}
                          onClick={this.exportAll}
                        >
                          Export All
                        </button>
                        <div className="row">
                          <div className="col-md-3">
                            <select
                              id="drop"
                              className="form-control"
                              onChange={this.dropDownValueChange}
                              style={{ marginTop: "5px" }}
                            >
                              <option selected disabled>
                                Select Format
                              </option>
                              {this.state.getFormats.map((getData) => (
                                <option value={getData.FormatName}>
                                  {getData.FormatName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-3">
                            <select
                              className="form-control"
                              name="InwardType"
                              value={this.state.InwardType}
                              style={{ marginTop: "5px" }}
                              onChange={this.dropDownInwardType}
                            >
                              <option>Natural</option>
                              <option>Labgrown</option>
                            </select>
                          </div>
                        </div>

                        <div
                          className="dropdown-menu removelist"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <ul>
                            {[
                              { key: "Stock", label: "Stock" },
                              { key: "VendorStock", label: "Vendor Stock" },
                              { key: "VendorName", label: "Vendor Name" },
                              { key: "Availability", label: "Availability" },
                              { key: "Shape", label: "Shape" },
                              { key: "Weight", label: "Weight" },
                              { key: "Color", label: "Color" },
                              { key: "Clarity", label: "Clarity" },
                              { key: "CutGrade", label: "Cut Grade" },
                              { key: "Polish", label: "Polish" },
                              { key: "Symmetry", label: "Symmetry" },
                              {
                                key: "FluorescenceIntensity",
                                label: "Fluorescence Intensity",
                              },
                              {
                                key: "FluorescenceColor",
                                label: "Fluorescence Color",
                              },
                              { key: "Measurements", label: "Measurements" },
                              { key: "Lab", label: "Lab" },
                              { key: "Certificate", label: "Certificate" },
                              { key: "Treatment", label: "Treatment" },
                              {
                                key: "VendorDiscount",
                                label: "Vendor Discount",
                              },
                              { key: "CashPrice", label: "Cash Price" },
                              {
                                key: "CashPriceDiscount",
                                label: "Cash Price Discount",
                              },
                              { key: "FancyColor", label: "Fancy Color" },
                              {
                                key: "FancyColorIntensity",
                                label: "Fancy Color Intensity",
                              },
                              {
                                key: "FancyColorOvertone",
                                label: "Fancy Color Overtone",
                              },
                              { key: "Depth", label: "Depth" },
                              { key: "Table", label: "Table" },
                              { key: "GirdleThin", label: "Girdle Thin" },
                              { key: "GirdleThick", label: "Girdle Thick" },
                              { key: "Girdle", label: "Girdle" },
                              {
                                key: "GirdleCondition",
                                label: "Girdle Condition",
                              },
                              { key: "CuletSize", label: "Culet Size" },
                              {
                                key: "CuletCondition",
                                label: "Culet Condition",
                              },
                              { key: "CrownHeight", label: "Crown Height" },
                              { key: "CrownAngle", label: "Crown Angle" },
                              { key: "PavilionDepth", label: "Pavilion Depth" },
                              { key: "PavilionAngle", label: "Pavilion Angle" },
                              {
                                key: "LaserInscription",
                                label: "Laser Inscription",
                              },
                              { key: "CertComment", label: "Cert Comment" },
                              { key: "Country", label: "Country" },
                              { key: "State", label: "State" },
                              { key: "City", label: "City" },
                              {
                                key: "TimeToLocation",
                                label: "Time To Location",
                              },
                              {
                                key: "IsMatchedPairSeparable",
                                label: "Is Matched Pair Separable",
                              },
                              { key: "PairStock", label: "Pair Stock" },
                              {
                                key: "AllowRapLinkFeed",
                                label: "Allow Rap Link Feed",
                              },
                              { key: "ParcelStones", label: "Parcel Stones" },
                              {
                                key: "CertificateFilename",
                                label: "Certificate Filename",
                              },
                              { key: "DiamondImage", label: "Diamond Image" },
                              { key: "SarinLoupe", label: "Sarin Loupe" },
                              { key: "TradeShow", label: "Trade Show" },
                              { key: "KeyToSymbols", label: "Key To Symbols" },
                              { key: "Shade", label: "Shade" },
                              { key: "StarLength", label: "Star Length" },
                              {
                                key: "CenterInclusion",
                                label: "Center Inclusion",
                              },
                              {
                                key: "BlackInclusion",
                                label: "Black Inclusion",
                              },
                              { key: "MemberComment", label: "Member Comment" },
                              {
                                key: "ReportIssueDate",
                                label: "Report Issue Date",
                              },
                              { key: "ReportType", label: "Report Type" },
                              { key: "LabLocation", label: "Lab Location" },
                              { key: "Brand", label: "Brand" },
                              { key: "Milky", label: "Milky" },
                              {
                                key: "RapnetDiscount",
                                label: "Rapnet Discount",
                              },
                              { key: "NetValue", label: "Net Value" },
                              { key: "Rap", label: "Rap" },
                              { key: "RapNetPrice", label: "Rap Net Price" },
                            ].map(({ key, label }) => (
                              <li key={key}>
                                <label>
                                  <input
                                    type="checkbox"
                                    value={`js${key}`}
                                    onClick={(e) =>
                                      this.hideShowColumn(e, `js${key}`)
                                    }
                                    defaultChecked
                                  />{" "}
                                  {label}
                                </label>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <div className="card-body">
                        {this.state.inwards && this.state.value ? (
                          <input
                            type="button"
                            value="Csv Export"
                            className="btn btn-secondary"
                            onClick={(e) => this.CsvAfterSearch(e)}
                          />
                        ) : (
                          ""
                        )}
                        <div className="table-responsive scroll ">
                          <div
                            style={{ overflowX: "auto" }}
                            className="tableFixHead "
                          >
                            {this.state.inwards && this.state.value !== "" ? (
                              <table
                                id="example1"
                                className="table table-bordered table-striped "
                              >
                                <thead>
                                  <tr>
                                    {/* <th><div>{this.state.status ? <Link className="removestyle  " onClick={(e) => this.loadPage('SrNo')}>SR No.<i class="fa fa-caret-up" aria-hidden="true"></i>:<i class="fa fa-caret-down" aria-hidden="true"></i></Link>}</div></th> */}
                                    {/* {<th><div>this.state.status ? <Link className="removestyle  " onClick={(e) => this.loadPage('SrNo')}>SR No.<i class="fa fa-caret-up" aria-hidden="true"></i>:<i class="fa fa-caret-down" aria-hidden="true"></i></Link></div></th>} */}

                                    <th data-index="2" class="jsStock ">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Stock")
                                          }
                                        >
                                          Stock
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th
                                      data-index="3"
                                      className="jsVendorStock"
                                    >
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("VendorStock")
                                          }
                                        >
                                          VenderStock
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsPartyName">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("VendorName")
                                          }
                                        >
                                          VendorName
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsAvailability">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Availability")
                                          }
                                        >
                                          Availability
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsShape">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Shape")
                                          }
                                        >
                                          Shape
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsWeight">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Weight")
                                          }
                                        >
                                          Weight
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsColor">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Color")
                                          }
                                        >
                                          Color
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsClarity">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Clarity")
                                          }
                                        >
                                          Clarity
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsCutGrade">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("CutGrade")
                                          }
                                        >
                                          CutGrade
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsPolish">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Polish")
                                          }
                                        >
                                          Polish
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsSymmetry">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) => this.loadPage("SYMM")}
                                        >
                                          Symmentry
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsFluorescenceIntensity">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage(
                                              "FluorescenceIntensity"
                                            )
                                          }
                                        >
                                          FluorescenceIntensity
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsFluorescenceColor">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("FluorescenceColor")
                                          }
                                        >
                                          FluorescenceColor
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsMeasurements">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Measurements")
                                          }
                                        >
                                          Measurements
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsLab">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) => this.loadPage("Lab")}
                                        >
                                          Lab
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsCertificate">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Certificate")
                                          }
                                        >
                                          Certificate
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsTreatment">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Treatment")
                                          }
                                        >
                                          Treatment
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>

                                    <th className="jsVendorDiscount">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("VendorDiscount")
                                          }
                                        >
                                          VendorDiscount
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>

                                    <th className="jsCashPrice">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("CashPrice")
                                          }
                                        >
                                          CashPrice
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsCashPriceDiscount">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("CashPriceDiscount")
                                          }
                                        >
                                          CashPriceDiscount
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsFancyColor">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("FancyColor")
                                          }
                                        >
                                          FancyColor
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsFancyColorIntensity">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("FancyColorIntensity")
                                          }
                                        >
                                          FancyColorIntensity
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsFancyColorOvertone">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("FancyColorOvertone")
                                          }
                                        >
                                          FancyColorOvertone
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsDepth">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Depth")
                                          }
                                        >
                                          Depth
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsTable">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Table")
                                          }
                                        >
                                          Table
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsGirdleThin">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("GirdleThin")
                                          }
                                        >
                                          GirdleThin
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsGirdleThick">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("GirdleThick")
                                          }
                                        >
                                          GirdleThick
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsGirdle">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Girdle")
                                          }
                                        >
                                          Girdle
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsGirdleCondition">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("GirdleCondition")
                                          }
                                        >
                                          GirdleCondition
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsCuletSize">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("CuletSize")
                                          }
                                        >
                                          CuletSize
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsCuletCondition">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("CuletCondition")
                                          }
                                        >
                                          CuletCondition
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsCrownHeight">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("CrownHeight")
                                          }
                                        >
                                          CrownHeight
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsCrownAngle">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("CrownAngle")
                                          }
                                        >
                                          CrownAngle
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsPavilionDepth">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("PavilionDepth")
                                          }
                                        >
                                          PavilionDepth
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsPavilionAngle">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("PavilionAngle")
                                          }
                                        >
                                          PavilionAngle
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsLaserInscription">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("LaserInscription")
                                          }
                                        >
                                          LaserInscription
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsCertcomment">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Certcomment")
                                          }
                                        >
                                          Certcomment
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsCountry">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Country")
                                          }
                                        >
                                          Country
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsState">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("State")
                                          }
                                        >
                                          State
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsCity">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) => this.loadPage("City")}
                                        >
                                          City
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsTimetolocation">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Timetolocation")
                                          }
                                        >
                                          Timetolocation
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsIsMatchedPairSeparable">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage(
                                              "IsMatchedPairSeparable"
                                            )
                                          }
                                        >
                                          IsMatchedPairSeparable
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsPairStock">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("PairStock")
                                          }
                                        >
                                          PairStock
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsAllowRapLinkFeed">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("AllowRapLinkFeed")
                                          }
                                        >
                                          AllowRapLinkFeed
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsParcelStones">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("ParcelStones")
                                          }
                                        >
                                          ParcelStones
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jscertificateFilename">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("CertificateFilename")
                                          }
                                        >
                                          CertificateFilename
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsDiamondImage">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("DiamondImage")
                                          }
                                        >
                                          DiamondImage
                                          {this.state.status ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsSarinLoupe">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("SarinLoupe")
                                          }
                                        >
                                          SarinLoupe
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsTradeShow">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("TradeShow")
                                          }
                                        >
                                          TradeShow
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsKeytosymbols">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Keytosymbols")
                                          }
                                        >
                                          Keytosymbols
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsShade">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Shade")
                                          }
                                        >
                                          Shade
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsStarLength">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("StarLength")
                                          }
                                        >
                                          StarLength
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsCenterInclusion">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("CenterInclusion")
                                          }
                                        >
                                          CenterInclusion
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsBlackInclusion">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("BlackInclusion")
                                          }
                                        >
                                          BlackInclusion
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsMemberComment">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("MemberComment")
                                          }
                                        >
                                          MemberComment
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsReportIssueDate">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("ReportIssueDate")
                                          }
                                        >
                                          ReportIssueDate
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsReportType">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("ReportType")
                                          }
                                        >
                                          ReportType
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsLabLocation">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("LabLocation")
                                          }
                                        >
                                          LabLocation
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsBrand">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Brand")
                                          }
                                        >
                                          Brand
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsMilky">
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("Milky")
                                          }
                                        >
                                          Milky
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsRapnetDiscount">
                                      <div>
                                        <Link
                                          className="removestyle"
                                          onClick={(e) =>
                                            this.loadPage("RapnetDiscount")
                                          }
                                        >
                                          RapnetDiscount
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsRapNetPrice">
                                      <div>
                                        <Link
                                          className="removestyle"
                                          onClick={(e) =>
                                            this.loadPage("RapNetPrice")
                                          }
                                        >
                                          RapNetPrice
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsRap">
                                      <div>
                                        <Link
                                          className="removestyle"
                                          onClick={(e) => this.loadPage("Rap")}
                                        >
                                          Rap
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th className="jsNetValue">
                                      <div>
                                        <Link
                                          className="removestyle"
                                          onClick={(e) =>
                                            this.loadPage("NetValue")
                                          }
                                        >
                                          NetValue
                                          {this.state.status == "asc" ? (
                                            <i
                                              className="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              className="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    {/* <th>Export Excel</th> */}
                                    <th>
                                      <div>
                                        <Link
                                          className="removestyle  "
                                          onClick={(e) =>
                                            this.loadPage("FinalPrice")
                                          }
                                        >
                                          INWARD TYPE
                                          {this.state.status == "asc" ? (
                                            <i
                                              class="fa fa-caret-down"
                                              aria-hidden="true"
                                            ></i>
                                          ) : (
                                            <i
                                              class="fa fa-caret-up"
                                              aria-hidden="true"
                                            ></i>
                                          )}
                                        </Link>
                                      </div>
                                    </th>
                                    <th>Delete</th>
                                    <th>Edit</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {this.state.inwards.map((view, index) => (
                                    <tr data-index={index}>
                                      <td class="jsStock">{view.Stock}</td>
                                      <td className="jsVendorStock">
                                        {view.VendorStock}
                                      </td>
                                      {/* {

                                                                        this.state.inwardData.filter((IID) => IID._id === view.InwardID).map((inwarddetails) => {
                                                                            return (
                                                                                <Fragment>

                                                                                    <td>{inwarddetails.partyID.partyName}</td>


                                                                                </Fragment>

                                                                            )
                                                                        })
                                                                    } */}
                                      <td className="jsPartyName">
                                        {view.PartyName}
                                      </td>
                                      <td className="jsAvailability">
                                        {view.Availability}
                                      </td>
                                      <td className="jsShape">{view.Shape}</td>
                                      <td className="jsWeight">
                                        {view.Weight}
                                      </td>
                                      <td className="jsColor">{view.Color}</td>
                                      <td className="jsClarity">
                                        {view.Clarity}
                                      </td>
                                      <td className="jsCutGrade">
                                        {view.CutGrade}
                                      </td>
                                      <td className="jsPolish">
                                        {view.Polish}
                                      </td>
                                      <td className="jsSymmetry">
                                        {view.Symmetry}
                                      </td>
                                      <td className="jsFluorescenceIntensity">
                                        {view.FluorescenceIntensity}
                                      </td>
                                      <td className="jsFluorescenceColor">
                                        {view.FluorescenceColor}
                                      </td>
                                      <td className="jsMeasurements">
                                        {view.Measurements}
                                      </td>
                                      <td className="jsLab">{view.Lab}</td>
                                      <td className="jsCertificate">
                                        {view.Certificate}
                                      </td>
                                      <td className="jsTreatment">
                                        {view.Treatment}
                                      </td>
                                      <td className="jsVendorDiscount">
                                        {view.VendorDiscount}
                                      </td>
                                      <td className="jsCashPrice">
                                        {view.CashPrice}
                                      </td>
                                      <td className="jsCashPriceDiscount">
                                        {view.CashPriceDiscount}
                                      </td>
                                      <td className="jsFancyColor">
                                        {view.FancyColor}
                                      </td>
                                      <td className="jsFancyColorIntensity">
                                        {view.FancyColorIntensity}
                                      </td>
                                      <td className="jsFancyColorOvertone">
                                        {view.FancyColorOvertone}
                                      </td>
                                      <td className="jsDepth">{view.Depth}</td>
                                      <td className="jsTable">{view.Table}</td>
                                      <td className="jsGirdleThin">
                                        {view.GirdleThin}
                                      </td>
                                      <td className="jsGirdleThick">
                                        {view.GirdleThick}
                                      </td>
                                      <td className="jsGirdle">
                                        {view.Girdle}
                                      </td>
                                      <td className="jsGirdleCondition">
                                        {view.GirdleCondition}
                                      </td>
                                      <td className="jsCuletSize">
                                        {view.CuletSize}
                                      </td>
                                      <td className="jsCuletCondition">
                                        {view.CuletCondition}
                                      </td>
                                      <td className="jsCrownHeight">
                                        {view.CrownHeighht}
                                      </td>
                                      <td className="jsCrownAngle">
                                        {view.CrownAngle}
                                      </td>
                                      <td className="jsPavilionDepth">
                                        {view.PavilionDepth}
                                      </td>
                                      <td className="jsPavilionAngle">
                                        {view.PavilionAngle}
                                      </td>
                                      <td className="jsLaserInscription">
                                        {view.LaserInscription}
                                      </td>
                                      <td className="jsCertcomment">
                                        {view.Certcomment}
                                      </td>
                                      <td className="jsCountry">
                                        {view.Country}
                                      </td>
                                      <td className="jsState">{view.State}</td>
                                      <td className="jsCity">{view.City}</td>
                                      <td className="jsTimetolocation">
                                        {view.Timetolocation}
                                      </td>
                                      <td className="jsIsMatchedPairSeparable">
                                        {view.IsMatchedPairSeparable}
                                      </td>
                                      <td className="jsPairStock">
                                        {view.PairStock}
                                      </td>
                                      <td className="jsAllowRapLinkFeed">
                                        {view.AllowRapLinkFeed}
                                      </td>
                                      <td className="jsParcelStones">
                                        {view.ParcelStones}
                                      </td>
                                      <td className="jscertificateFilename">
                                        {view.CertificateFilename}
                                      </td>
                                      <td className="jsDiamondImage">
                                        {view.DiamondImage}
                                      </td>
                                      <td className="jsSarinLoupe">
                                        {view.SarinLoupe}
                                      </td>
                                      <td className="jsTradeShow">
                                        {view.TardeShow}
                                      </td>
                                      <td className="jsKeytosymbols">
                                        {view.Keytosymbols}
                                      </td>
                                      <td className="jsShade">{view.Shade}</td>
                                      <td className="jsStarLength">
                                        {view.StarLength}
                                      </td>
                                      <td className="jsCenterInclusion">
                                        {view.CenterInclusion}
                                      </td>
                                      <td className="jsBlackInclusion">
                                        {view.BlackInclusion}
                                      </td>
                                      <td className="jsMemberComment">
                                        {view.MemberComment}
                                      </td>
                                      <td className="jsReportIssueDate">
                                        {view.ReportIssueDate}
                                      </td>
                                      <td className="jsReportType">
                                        {view.ReportType}
                                      </td>
                                      <td className="jsLabLocation">
                                        {view.LabLocation}
                                      </td>
                                      <td className="jsBrand">{view.Brand}</td>
                                      <td className="jsMilky">{view.Milky}</td>
                                      <td className="jsRapnetDiscount">
                                        {view.RapnetDiscount}
                                      </td>
                                      <td className="jsRapNetPrice">
                                        {view.RapNetPrice}
                                        {!view.RapNetPrice &&
                                          this.state.partyMarkup[
                                            view.PartyName
                                          ] &&
                                          view.FancyColorPrice &&
                                          (
                                            view.FancyColorPrice +
                                            this.state.partyMarkup[
                                              view.PartyName
                                            ]
                                          ).toFixed(2)}
                                      </td>
                                      <td className="jsRap">{view.Rap}</td>
                                      <td className="jsNetValue">
                                        {view.NetValue}
                                        {!view.NetValue &&
                                          this.state.partyMarkup[
                                            view.PartyName
                                          ] &&
                                          view.FancyColorPrice &&
                                          (
                                            (view.FancyColorPrice +
                                              this.state.partyMarkup[
                                                view.PartyName
                                              ]) *
                                            view.Weight
                                          ).toFixed(2)}
                                      </td>

                                      <td>{view.InwardType}</td>
                                      {/* <td><Link to={`/addInward/${view._id}`}><span style={{ color: "#17a2b8" }}><i className="fa fa-edit"></i></span></Link></td> */}
                                      {/* <td><Link onClick={(e) => this.ExportExcel(e, view._id)}><i className="fa fa-upload" style={{ color: "#17a2b8" }}></i></Link></td> */}
                                      <td>
                                        <Link
                                          onClick={(e) =>
                                            this.DeleteInward(e, view._id)
                                          }
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "#c93d3a" }}
                                          ></i>
                                        </Link>
                                      </td>
                                      <td>
                                        <Link to={`/addInward/${view._id}`}>
                                          <span style={{ color: "#17a2b8" }}>
                                            <i className="fa fa-edit"></i>
                                          </span>
                                        </Link>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              // Table starts here
                              <Fragment>
                                <table
                                  id="example1"
                                  className="table table-bordered table-striped "
                                >
                                  <thead>
                                    <tr style={{ position: "sticky" }}>
                                      {/* <th><div>{this.state.status ? <Link className="removestyle  " onClick={(e) => this.loadPage('SrNo')}>SR No.<i class="fa fa-caret-up" aria-hidden="true"></i>:<i class="fa fa-caret-down" aria-hidden="true"></i></Link>}</div></th> */}
                                      {/* {<th><div>this.state.status ? <Link className="removestyle  " onClick={(e) => this.loadPage('SrNo')}>SR No.<i class="fa fa-caret-up" aria-hidden="true"></i>:<i class="fa fa-caret-down" aria-hidden="true"></i></Link></div></th>} */}

                                      <th data-index="2" class="jsStock">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Stock")
                                            }
                                          >
                                            Stock
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th
                                        data-index="3"
                                        className="jsVendorStock"
                                      >
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("VendorStock")
                                            }
                                          >
                                            VenderStock
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsPartyName">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("VendorName")
                                            }
                                          >
                                            VendorName
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsAvailability">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Availability")
                                            }
                                          >
                                            Availability
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsShape">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Shape")
                                            }
                                          >
                                            Shape
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsWeight">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Weight")
                                            }
                                          >
                                            Weight
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsColor">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Color")
                                            }
                                          >
                                            Color
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsClarity">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Clarity")
                                            }
                                          >
                                            Clarity
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsCutGrade">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("CutGrade")
                                            }
                                          >
                                            CutGrade
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsPolish">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Polish")
                                            }
                                          >
                                            Polish
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsSymmetry">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("SYMM")
                                            }
                                          >
                                            Symmentry
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsFluorescenceIntensity">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage(
                                                "FluorescenceIntensity"
                                              )
                                            }
                                          >
                                            FluorescenceIntensity
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsFluorescenceColor">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("FluorescenceColor")
                                            }
                                          >
                                            FluorescenceColor
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsMeasurements">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Measurements")
                                            }
                                          >
                                            Measurements
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsLab">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Lab")
                                            }
                                          >
                                            Lab
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsCertificate">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Certificate")
                                            }
                                          >
                                            Certificate
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsTreatment">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Treatment")
                                            }
                                          >
                                            Treatment
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsVendorDiscount">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("VendorDiscount")
                                            }
                                          >
                                            VendorDiscount
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsCashPrice">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("CashPrice")
                                            }
                                          >
                                            CashPrice
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsCashPriceDiscount">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("CashPriceDiscount")
                                            }
                                          >
                                            CashPriceDiscount
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsFancyColor">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("FancyColor")
                                            }
                                          >
                                            FancyColor
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsFancyColorIntensity">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage(
                                                "FancyColorIntensity"
                                              )
                                            }
                                          >
                                            FancyColorIntensity
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsFancyColorOvertone">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage(
                                                "FancyColorOvertone"
                                              )
                                            }
                                          >
                                            FancyColorOvertone
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsDepth">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Depth")
                                            }
                                          >
                                            Depth
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsTable">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Table")
                                            }
                                          >
                                            Table
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsGirdleThin">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("GirdleThin")
                                            }
                                          >
                                            GirdleThin
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsGirdleThick">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("GirdleThick")
                                            }
                                          >
                                            GirdleThick
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsGirdle">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Girdle")
                                            }
                                          >
                                            Girdle
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsGirdleCondition">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("GirdleCondition")
                                            }
                                          >
                                            GirdleCondition
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsCuletSize">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("CuletSize")
                                            }
                                          >
                                            CuletSize
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsCuletCondition">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("CuletCondition")
                                            }
                                          >
                                            CuletCondition
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsCrownHeight">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("CrownHeight")
                                            }
                                          >
                                            CrownHeight
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsCrownAngle">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("CrownAngle")
                                            }
                                          >
                                            CrownAngle
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsPavilionDepth">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("PavilionDepth")
                                            }
                                          >
                                            PavilionDepth
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsPavilionAngle">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("PavilionAngle")
                                            }
                                          >
                                            PavilionAngle
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsLaserInscription">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("LaserInscription")
                                            }
                                          >
                                            LaserInscription
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsCertcomment">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Certcomment")
                                            }
                                          >
                                            Certcomment
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsCountry">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Country")
                                            }
                                          >
                                            Country
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsState">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("State")
                                            }
                                          >
                                            State
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsCity">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("City")
                                            }
                                          >
                                            City
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsTimetolocation">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Timetolocation")
                                            }
                                          >
                                            Timetolocation
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsIsMatchedPairSeparable">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage(
                                                "IsMatchedPairSeparable"
                                              )
                                            }
                                          >
                                            IsMatchedPairSeparable
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsPairStock">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("PairStock")
                                            }
                                          >
                                            PairStock
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsAllowRapLinkFeed">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("AllowRapLinkFeed")
                                            }
                                          >
                                            AllowRapLinkFeed
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsParcelStones">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("ParcelStones")
                                            }
                                          >
                                            ParcelStones
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jscertificateFilename">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage(
                                                "CertificateFilename"
                                              )
                                            }
                                          >
                                            CertificateFilename
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsDiamondImage">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("DiamondImage")
                                            }
                                          >
                                            DiamondImage
                                            {this.state.status ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsSarinLoupe">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("SarinLoupe")
                                            }
                                          >
                                            SarinLoupe
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsTradeShow">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("TradeShow")
                                            }
                                          >
                                            TradeShow
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsKeytosymbols">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Keytosymbols")
                                            }
                                          >
                                            Keytosymbols
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsShade">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Shade")
                                            }
                                          >
                                            Shade
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsStarLength">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("StarLength")
                                            }
                                          >
                                            StarLength
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsCenterInclusion">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("CenterInclusion")
                                            }
                                          >
                                            CenterInclusion
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsBlackInclusion">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("BlackInclusion")
                                            }
                                          >
                                            BlackInclusion
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsMemberComment">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("MemberComment")
                                            }
                                          >
                                            MemberComment
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsReportIssueDate">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("ReportIssueDate")
                                            }
                                          >
                                            ReportIssueDate
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsReportType">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("ReportType")
                                            }
                                          >
                                            ReportType
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsLabLocation">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("LabLocation")
                                            }
                                          >
                                            LabLocation
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsBrand">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Brand")
                                            }
                                          >
                                            Brand
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsMilky">
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("Milky")
                                            }
                                          >
                                            Milky
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsRapnetDiscount">
                                        <div>
                                          <Link
                                            className="removestyle"
                                            onClick={(e) =>
                                              this.loadPage("RapnetDiscount")
                                            }
                                          >
                                            RapnetDiscount
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsRapNetPrice">
                                        <div>
                                          <Link
                                            className="removestyle"
                                            onClick={(e) =>
                                              this.loadPage("RapNetPrice")
                                            }
                                          >
                                            RapNetPrice
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsRap">
                                        <div>
                                          <Link
                                            className="removestyle"
                                            onClick={(e) =>
                                              this.loadPage("Rap")
                                            }
                                          >
                                            Rap
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th className="jsNetValue">
                                        <div>
                                          <Link
                                            className="removestyle"
                                            onClick={(e) =>
                                              this.loadPage("NetValue")
                                            }
                                          >
                                            NetValue
                                            {this.state.status == "asc" ? (
                                              <i
                                                className="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                className="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      <th>
                                        <div>
                                          <Link
                                            className="removestyle  "
                                            onClick={(e) =>
                                              this.loadPage("FinalPrice")
                                            }
                                          >
                                            INWARD TYPE
                                            {this.state.status == "asc" ? (
                                              <i
                                                class="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            ) : (
                                              <i
                                                class="fa fa-caret-up"
                                                aria-hidden="true"
                                              ></i>
                                            )}
                                          </Link>
                                        </div>
                                      </th>
                                      {/* <th>Export Excel</th> */}
                                      <th>Delete</th>
                                      <th>Edit</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {/* {console.log(
                                      "Pages of items : ",
                                      pageOfItems
                                    )}
                                    {console.log(
                                      "new markup state : ",
                                      this.state.newMarkUpState
                                    )} */}
                                    {pageOfItems.map((view, index) => (
                                      <tr data-index={index}>
                                        <td class="jsStock">{view.Stock}</td>
                                        <td className="jsVendorStock">
                                          {view.VendorStock}
                                        </td>
                                        {/* {

                                                                        this.state.inwardData.filter((IID) => IID._id === view.InwardID).map((inwarddetails) => {
                                                                            return (
                                                                                <Fragment>

                                                                                    <td>{inwarddetails.partyID.partyName}</td>


                                                                                </Fragment>

                                                                            )
                                                                        })
                                                                    } */}
                                        <td className="jsPartyName">
                                          {view.PartyName}
                                        </td>
                                        <td className="jsAvailability">
                                          {view.Availability}
                                        </td>
                                        <td className="jsShape">
                                          {view.Shape}
                                        </td>
                                        <td className="jsWeight">
                                          {view.Weight}
                                        </td>
                                        <td className="jsColor">
                                          {view.Color
                                            ? view.Color
                                            : view.FancyColorIntensity +
                                              " " +
                                              view.FancyColorOvertone +
                                              " " +
                                              view.FancyColor}
                                        </td>
                                        <td className="jsClarity">
                                          {view.Clarity}
                                        </td>
                                        <td className="jsCutGrade">
                                          {view.CutGrade}
                                        </td>
                                        <td className="jsPolish">
                                          {view.Polish}
                                        </td>
                                        <td className="jsSymmetry">
                                          {view.Symmetry}
                                        </td>
                                        <td className="jsFluorescenceIntensity">
                                          {view.FluorescenceIntensity}
                                        </td>
                                        <td className="jsFluorescenceColor">
                                          {view.FluorescenceColor}
                                        </td>
                                        <td className="jsMeasurements">
                                          {view.Measurements}
                                        </td>
                                        <td className="jsLab">{view.Lab}</td>
                                        <td className="jsCertificate">
                                          {view.Certificate}
                                        </td>
                                        <td className="jsTreatment">
                                          {view.Treatment}
                                        </td>
                                        <td className="jsVendorDiscount">
                                          {view.VendorDiscount}
                                        </td>
                                        <td className="jsCashPrice">
                                          {view.CashPrice}
                                        </td>
                                        <td className="jsCashPriceDiscount">
                                          {view.CashPriceDiscount}
                                        </td>
                                        <td className="jsFancyColor">
                                          {view.FancyColor}
                                        </td>
                                        <td className="jsFancyColorIntensity">
                                          {view.FancyColorIntensity}
                                        </td>
                                        <td className="jsFancyColorOvertone">
                                          {view.FancyColorOvertone}
                                        </td>
                                        <td className="jsDepth">
                                          {view.Depth}
                                        </td>
                                        <td className="jsTable">
                                          {view.Table}
                                        </td>
                                        <td className="jsGirdleThin">
                                          {view.GirdleThin}
                                        </td>
                                        <td className="jsGirdleThick">
                                          {view.GirdleThick}
                                        </td>
                                        <td className="jsGirdle">
                                          {view.Girdle}
                                        </td>
                                        <td className="jsGirdleCondition">
                                          {view.GirdleCondition}
                                        </td>
                                        <td className="jsCuletSize">
                                          {view.CuletSize}
                                        </td>
                                        <td className="jsCuletCondition">
                                          {view.CuletCondition}
                                        </td>
                                        <td className="jsCrownHeight">
                                          {view.CrownHeighht}
                                        </td>
                                        <td className="jsCrownAngle">
                                          {view.CrownAngle}
                                        </td>
                                        <td className="jsPavilionDepth">
                                          {view.PavilionDepth}
                                        </td>
                                        <td className="jsPavilionAngle">
                                          {view.PavilionAngle}
                                        </td>
                                        <td className="jsLaserInscription">
                                          {view.LaserInscription}
                                        </td>
                                        <td className="jsCertcomment">
                                          {view.Certcomment}
                                        </td>
                                        <td className="jsCountry">
                                          {view.Country}
                                        </td>
                                        <td className="jsState">
                                          {view.State}
                                        </td>
                                        <td className="jsCity">{view.City}</td>
                                        <td className="jsTimetolocation">
                                          {view.Timetolocation}
                                        </td>
                                        <td className="jsIsMatchedPairSeparable">
                                          {view.IsMatchedPairSeparable}
                                        </td>
                                        <td className="jsPairStock">
                                          {view.PairStock}
                                        </td>
                                        <td className="jsAllowRapLinkFeed">
                                          {view.AllowRapLinkFeed}
                                        </td>
                                        <td className="jsParcelStones">
                                          {view.ParcelStones}
                                        </td>
                                        <td className="jscertificateFilename">
                                          {view.CertificateFilename}
                                        </td>
                                        <td className="jsDiamondImage">
                                          {view.DiamondImage}
                                        </td>
                                        <td className="jsSarinLoupe">
                                          {view.SarinLoupe}
                                        </td>
                                        <td className="jsTradeShow">
                                          {view.TardeShow}
                                        </td>
                                        <td className="jsKeytosymbols">
                                          {view.Keytosymbols}
                                        </td>
                                        <td className="jsShade">
                                          {view.Shade}
                                        </td>
                                        <td className="jsStarLength">
                                          {view.StarLength}
                                        </td>
                                        <td className="jsCenterInclusion">
                                          {view.CenterInclusion}
                                        </td>
                                        <td className="jsBlackInclusion">
                                          {view.BlackInclusion}
                                        </td>
                                        <td className="jsMemberComment">
                                          {view.MemberComment}
                                        </td>
                                        <td className="jsReportIssueDate">
                                          {view.ReportIssueDate}
                                        </td>
                                        <td className="jsReportType">
                                          {view.ReportType}
                                        </td>
                                        <td className="jsLabLocation">
                                          {view.LabLocation}
                                        </td>
                                        <td className="jsBrand">
                                          {view.Brand}
                                        </td>
                                        <td className="jsMilky">
                                          {view.Milky}
                                        </td>
                                        <td className="jsRapnetDiscount">
                                          {view.RapnetDiscount}
                                        </td>
                                        {/* <td className="jsRapNetPrice">
                                          {view.RapNetPrice}{' '}
                                        </td> */}

                                        {/* Updated on 14/09/24 */}
                                        <td className="jsRapNetPrice">
                                          {/** if view.RapNetPrice is not present then:
                                           *      if 'fancy' means view.Color=""
                                           *          then print view.FancyColorPrice + this.state.partyMarkup[view.PartyName]
                                           *
                                           *
                                           */}

                                          {/* {
                                            view.RapNetPrice
                                              ? view.RapNetPrice // If view.RapNetPrice exists, display it
                                              : view.Color === "" &&
                                                this.state.partyMarkup[
                                                  view.PartyName
                                                ] &&
                                                view.FancyColorPrice
                                              ? view.FancyColorPrice +
                                                this.state.partyMarkup[
                                                  view.PartyName
                                                ] // If view.Color is empty (i.e., "fancy") and both FancyColorPrice and partyMarkup are present, display the sum
                                              : null // If neither RapNetPrice nor the conditions for "fancy" color are met, display nothing
                                          } */}

                                          {/* Update 1 */}

                                          {
                                          this.state.newMarkUpState?.[
                                            view.PartyName
                                          ]?.discount === true
                                            ? Math.ceil(
                                                view.Rap -
                                                  (Math.abs(
                                                    view?.VendorDiscount
                                                  ) /
                                                    100) *
                                                    view.Rap
                                              )
                                            : view.Color === ""
                                            ? Math.ceil(
                                                view?.Rap +
                                                  this.state.newMarkUpState?.[
                                                    view.PartyName
                                                  ]?.FancyMarkup
                                              )
                                            : Math.ceil(
                                                view.Rap +
                                                  this.state.newMarkUpState?.[
                                                    view.PartyName
                                                  ]?.WhiteMarkup
                                              )}
                                        </td>

                                        <td className="jsRap">{view.Rap}</td>
                                        {/* <td className="jsNetValue">
                                          {view.NetValue}
                                        </td> */}

                                        {/* Updated on 14/09/24 */}
                                        <td className="jsNetValue">
                                          {/**
                                           *
                                           *    // Condition when diamond is fancy
                                           *    if(this.state.partyMarkup[view.PartyName] && view.FancyColorPrice) {
                                           *        print (view.FancyColorPrice + this.state.partyMarkup[view.PartyName])*view.Weight
                                           *    }
                                           *
                                           *    // condition when diamond is white
                                           *    else {
                                           *        print (view.NetValue + this.state.newMarkUps[view.PartyName].WhiteMarkup)*view.Weight
                                           *     }
                                           *
                                           *
                                           */}

                                          {/* {
                                            view && view.Color === ""
                                              ? ((view.FancyColorPrice || 0) +
                                                  (this.state.partyMarkup?.[
                                                    view.PartyName
                                                  ] || 0)) *
                                                view.Weight.toFixed(2) // Default weight to 1 if undefined
                                              : this.state.newMarkUpState?.[
                                                  view.PartyName
                                                ]?.WhiteMarkup
                                              ? (view.NetValue || 0) +
                                                (this.state.newMarkUpState?.[
                                                  view.PartyName
                                                ]?.WhiteMarkup || 0)
                                              : view?.NetValue || "N/A" // Fallback to "N/A" if NetValue is undefined
                                          } */}

                                          {/* Update 1 */}
                                          {
                                          this.state.newMarkUpState?.[
                                            view.PartyName
                                          ]?.discount === true
                                            ? Math.ceil(
                                                (view.Rap -
                                                  (Math.abs(
                                                    view?.VendorDiscount
                                                  ) /
                                                    100) *
                                                    view.Rap) * view.Weight
                                              )
                                            : view.Color === ""
                                            ? Math.ceil(
                                                (view?.Rap +
                                                  this.state.newMarkUpState?.[
                                                    view.PartyName
                                                  ]?.FancyMarkup) * view.Weight
                                              )
                                            : Math.ceil(
                                                (view.Rap +
                                                  this.state.newMarkUpState?.[
                                                    view.PartyName
                                                  ]?.WhiteMarkup) * view.Weight
                                              )}
                                        </td>

                                        <td>{view.InwardType}</td>
                                        {/* <td><Link onClick={(e) => this.ExportExcel(e, view._id)}><i className="fa fa-upload" style={{ color: "#17a2b8" }}></i></Link></td> */}
                                        <td>
                                          <Link
                                            onClick={(e) =>
                                              this.DeleteInward(e, view._id)
                                            }
                                          >
                                            <center>
                                              <span
                                                style={{ color: "#c93d3a" }}
                                              >
                                                <i className="fa fa-trash"></i>
                                              </span>
                                            </center>
                                          </Link>
                                        </td>
                                        <td>
                                          <Link to={`/addInward/${view._id}`}>
                                            <center>
                                              <span
                                                style={{ color: "#17a2b8" }}
                                              >
                                                <i className="fa fa-edit"></i>
                                              </span>
                                            </center>
                                          </Link>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </Fragment>
                            )}
                          </div>
                          <div className="showingtotal">
                            {"Showing  " +
                              parseInt(pager.startIndex + 1) +
                              "  to " +
                              parseInt(pager.endIndex + 1) +
                              "  of " +
                              pager.totalItems +
                              "  entries"}
                          </div>
                          {pager.pages && pager.pages.length && (
                            <ul
                              className="pagination"
                              style={{ float: "right", marginTop: "5px" }}
                            >
                              <li
                                className={`page-item previous-item ${
                                  pager.currentPage == 1 ? "disabled" : ""
                                }`}
                              >
                                <Link
                                  to={{ search: `?page=${1}` }}
                                  className="page-link"
                                >
                                  First
                                </Link>
                              </li>
                              <li
                                className={`page-item previous-item ${
                                  pager.currentPage == 1 ? "disabled" : ""
                                }`}
                              >
                                <Link
                                  to={{
                                    search: `?page=${pager.currentPage - 1}`,
                                  }}
                                  className="page-link"
                                >
                                  Previous
                                </Link>
                              </li>

                              {pager.pages.map((page) => (
                                <li
                                  key={page}
                                  className={`page-item number-item ${
                                    this.state.page === page ? "active" : ""
                                  }`}
                                >
                                  <Link
                                    to={{ search: `?page=${page}` }}
                                    className="page-link"
                                  >
                                    {page}
                                  </Link>
                                </li>
                              ))}

                              <li
                                className={`page-item next-item ${
                                  pager.currentPage === pager.totalPages
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                <Link
                                  to={{
                                    search: `?page=${
                                      parseInt(pager.currentPage) + 1
                                    }`,
                                  }}
                                  className="page-link"
                                >
                                  Next
                                </Link>
                              </li>
                              <li
                                className={`page-item last-item ${
                                  pager.currentPage === pager.totalPages
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                <Link
                                  to={{ search: `?page=${pager.totalPages}` }}
                                  className="page-link"
                                >
                                  Last
                                </Link>
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewInward;
